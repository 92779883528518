const reducer = (state, action) => {
  const { type, payload } = action;
  console.log(type);
  switch (type) {
    case "login_start":
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
      };
    case "login_success":
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        loginMessage: "",
        token: payload.token,
      };
    case "login_failure":
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        loginMessage: payload.message,
      };
    case "query_start":
      return {
        ...state,
        querying: false,
        queryMessage: "Querying RAMM",
        data: [],
      };
    case "query_success":
      return {
        ...state,
        querying: true,
        queryMessage: "",
        data: payload.data,
      };
    case "query_failure":
      return {
        ...state,
        queryMessage: payload.message,
        querying: false,
        data: [],
      };
    case "load_bulk_query_result":
      state.queries[payload.query].results = payload.data;
      return { ...state };
    default:
      console.log("error - no matching action", type, payload);
      return { ...state };
  }
};

export default reducer;

import Spinner from "../components/Spinner";
import { useState } from "react";
import { format as d3Format } from "d3";
import ProjectSummary from "../components/ProjectSummary";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { useAppContext } from "../context/AppContext";
import { useQuery } from "@tanstack/react-query";
import { queries } from "../fetch/rammQueries";
import { fetchQuery } from "../fetch/fetchData";

const ProjectEstimates = () => {
  const dollarFormat = d3Format("$.3s");

  const [visible, setVisible] = useState(false);
  const [project, setProject] = useState("");

  const state = useAppContext();
  const sql = queries["ProjectClaims"].sql;
  const token = state.token;

  const qry = useQuery({
    queryKey: ["ProjectClaims", token],
    queryFn: async () => {
      const data = await fetchQuery({ token, sql });
      return data.data;
    },
    enabled: token !== "",
  });

  if (qry.isLoading) {
    return (
      <div
        style={{
          height: "50px",
          width: "50px",
          display: "flex",
          justifyContext: "center",
        }}
      >
        <Spinner></Spinner>
      </div>
    );
  }

  if (qry.isError) {
    return <div>{qry.error.message}</div>;
  }

  const handleClick = (e) => {
    setVisible(true);
    setProject(e.activeLabel);
  };
  function toggleModal() {
    setVisible(false);
  }

  const d = qry.data.sort((a,b) => { return (+b.est_tot) - (+a.est_tot)})

  return (
    <>
      <ResponsiveContainer height={400}>
        <BarChart
          onClick={handleClick}
          layout="vertical"
          width={500}
          height={300}
          data={d}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barCategoryGap={1}
          barGap={-1}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tickFormatter={(value) => dollarFormat(value)}
            type="number"
            domain={[0, 20000000]}
          />
          <Tooltip formatter={(value) => dollarFormat(value)} />
          <Legend wrapperStyle={{fontSize: "0.7rem"}} />
          <Bar name="Project Estimates" dataKey="est_tot" fill="#0db14b" stackId="a"/>
        <Bar name="Claims Accepted" dataKey="claim_tot" fill="#0066b3" stackId="b" />
        <Bar name="Claims Pending" dataKey="pend_tot" fill="#8884d8" stackId="b" />
          <YAxis
            dataKey="project_name"
            allowDataOverflow={true}
            type="category"
            style={{
              fontSize: "0.7rem",
              fill: "black",
              fontWeight: "700",
            }}
            mirror={true}
            width={400}
          />
        </BarChart>
      </ResponsiveContainer>
      <ProjectSummary isOpen={visible} project={project} toggle={toggleModal} />
    </>
  );
};

export default ProjectEstimates;

export const queries = {
  CostProgress: {
    results: [],
    sql: `
    With base_dispatch as (
      SELECT
          contract_id, dispatch_id 
              from mt_dispatch
                  Where call_status not in ('N')
                          and
                      contract_id in (2018, 2020, 2021, 2025)
                          and
                      contact_mobile Like '2025%'
                          and
                      police_event_id in ('20230213_08', '20230110_06','20230128_07')   
),
claim_lines as (
Select c.contract_id, c.dispatch_id, c.dispatch_claim_id, c.claim_no, c.account_2, c.claim_status, coalesce(c.claim_amount,0) as claim_amount
  from mt_dispatch_claim c inner join base_dispatch d on c.contract_id = d.contract_id and c.dispatch_id = d.dispatch_id
),
claim_lines_filt as (
Select *
  from claim_lines c
      Where 
           (c.contract_id = 2025 and c.account_2 = 2 and c.claim_status in ('A') ) 
          Or (c.contract_id = 2018 and c.account_2 = 32 and c.claim_status in ('A') ) 
          or (c.contract_id = 2020 and c.account_2 = 14 and c.claim_status in ('A') )
          or (c.contract_id = 2021 and c.account_2 = 10 and c.claim_status in ('A') )
),
sum_claimed as (
Select Sum(claim_amount) as sum_claim_amount from claim_lines_filt
),
pend_lines as (
Select c.contract_id, c.dispatch_id, c.dispatch_claim_id, c.claim_no, c.account_2, c.claim_status, coalesce(c.claim_amount,0) as claim_amount
  from mt_dispatch_claim c inner join base_dispatch d on c.contract_id = d.contract_id and c.dispatch_id = d.dispatch_id
),
pend_lines_filt as (
Select *
  from pend_lines c
      Where 
           (c.contract_id = 2025 and c.account_2 = 2 and c.claim_status in ('O','P') ) 
          Or (c.contract_id = 2018 and c.account_2 = 32 and c.claim_status in ('O','P') ) 
          or (c.contract_id = 2020 and c.account_2 = 14 and c.claim_status in ('O','P') )
          or (c.contract_id = 2021 and c.account_2 = 10 and c.claim_status in ('O','P') )
),
sum_pending as (
Select Sum(claim_amount) as sum_pending_amount from pend_lines_filt
),
est_lines as (
Select c.contract_id, c.dispatch_id, c.dispatch_claim_id, c.claim_status, coalesce(c.est_amount,0) as estimate_amount
  from mt_dispatch_claim c inner join base_dispatch d on c.contract_id = d.contract_id and c.dispatch_id = d.dispatch_id
),
est_lines_filt as (
Select *
  from est_lines c
      Where 
           c.claim_status = 'O'
),
sum_estimate as (
select sum(estimate_amount) as sum_estimate_amount from est_lines_filt
)
Select c.sum_claim_amount as claim_tot, p.sum_pending_amount as pend_tot, e.sum_estimate_amount as est_tot
from sum_claimed c, sum_pending p, sum_estimate e
    `,
  },
  CostProgressProjects: {
    results: [],
    sql: `With base_dispatch as (
      SELECT
          contract_id, dispatch_id, likely_id 
              from mt_dispatch
                  Where call_status not in ('N')
                          and
                      contract_id in (2018, 2020, 2021, 2025)
                          and
                      contact_mobile Like '2025%'
                          and
                      police_event_id in ('20230213_08', '20230110_06','20230128_07')   
),--Claim amount
claim_lines as (
select c.contract_id, c.dispatch_id, c.dispatch_claim_id, c.claim_no, c.account_2, c.claim_status, d.likely_id,
coalesce(c.est_amount, 0) as estimate_amount, coalesce(c.claim_amount,0) as claim_amount
  from mt_dispatch_claim c inner join base_dispatch d on c.contract_id = d.contract_id and c.dispatch_id = d.dispatch_id
),
claim_lines_accept as (
select contract_id, dispatch_id, likely_id, sum(claim_amount) as sum_claim_amount
  from claim_lines c
      where 
           (c.contract_id = 2025 and c.account_2 = 2 and c.claim_status in ('A') ) 
          Or (c.contract_id = 2018 and c.account_2 = 32 and c.claim_status in ('A') ) 
          or (c.contract_id = 2020 and c.account_2 = 14 and c.claim_status in ('A') )
          or (c.contract_id = 2021 and c.account_2 = 10 and c.claim_status in ('A') )
      group by contract_id, dispatch_id, likely_id
),--Pending amounts
claim_lines_pending as (
Select contract_id, dispatch_id, likely_id, sum(claim_amount) as sum_pending_amount
  from claim_lines c
      where 
           (c.contract_id = 2025 and c.account_2 = 2 and c.claim_status in ('O','P') ) 
          Or (c.contract_id = 2018 and c.account_2 = 32 and c.claim_status in ('O','P') ) 
          or (c.contract_id = 2020 and c.account_2 = 14 and c.claim_status in ('O','P') )
          or (c.contract_id = 2021 and c.account_2 = 10 and c.claim_status in ('O','P') )
      group by contract_id, dispatch_id, likely_id
),
claim_lines_estimate as (
select contract_id, dispatch_id, likely_id,  sum(estimate_amount) as sum_estimate_amount
  from claim_lines c
      where 
           c.claim_status = 'O'
      group by contract_id, dispatch_id, likely_id
),
projects as (select d.contract_id, d.dispatch_id, p.project_name, 
                  a.sum_claim_amount, b.sum_pending_amount, sum_estimate_amount
  from ud_cyclone_projects p inner join mt_dispatch d on cast(p.system_id as CHAR(3)) = d.likely_id
      left join claim_lines_accept a on a.contract_id = d.contract_id and a.dispatch_id = d.dispatch_id
      left join claim_lines_pending b on b.contract_id = d.contract_id and b.dispatch_id = d.dispatch_id
      left join claim_lines_estimate c on c.contract_id = d.contract_id and c.dispatch_id = d.dispatch_id
)

Select sum(coalesce(sum_claim_amount,0)) as claim_tot, 
sum(coalesce(sum_pending_amount,0)) as pend_tot, 
sum(coalesce(sum_estimate_amount,0)) as est_tot
   from projects
`,
  },
  FaultSeverity: {
    results: [],
    sql: `With base_dispatch as (
      SELECT
          contract_id, dispatch_id 
              from mt_dispatch
                  Where call_status not in ('N')
                          and
                      contract_id in (2018, 2020, 2021, 2025)
                          and
                      contact_mobile Like '2025%'
                          and
                      police_event_id in ('20230213_08', '20230110_06','20230128_07')   
),

faults as (Select c.contract_id, c.dispatch_id, c.asset_type, c.fault,
  case when c.disp_group_5 = 1 then 1 else 0 end as simple_fault,
  case when c.disp_group_5 = 2 then 1 else 0 end as minor_fault,
  case when c.disp_group_5 = 3 then 1 else 0 end as complex_fault
  from mt_dispatch c inner join base_dispatch d on c.contract_id = d.contract_id and c.dispatch_id = d.dispatch_id    
),

sort_faults as (Select a.cyclone_asset as fault_type,
  Sum(c.complex_fault) as complex,
  Sum(c.simple_fault) as simple,
  Sum(c.minor_fault) as minor
  from faults c inner join ud_assets_n_faults a on c.asset_type = a.asset_type and c.fault = a.fault_type
  Group by a.cyclone_asset
)
Select * from sort_faults order by (complex + simple + minor) desc; `,
  },
  FaultStatus: {
    results: [],
    sql: `With base_dispatch as (
      SELECT
          contract_id, dispatch_id 
              from mt_dispatch
                  Where call_status not in ('N')
                          and
                      contract_id in (2018, 2020, 2021, 2025)
                          and
                      contact_mobile Like '2025%'
                          and
                      police_event_id in ('20230213_08', '20230110_06','20230128_07')   
),

disp_status as (Select c.contract_id, c.dispatch_id, c.asset_type, c.fault, c.call_status, a.cyclone_asset
  from mt_dispatch c inner join base_dispatch d on c.contract_id = d.contract_id and c.dispatch_id = d.dispatch_id
                     inner join ud_assets_n_faults a on c.asset_type = a.asset_type and c.fault = a.fault_type 
), 
status_final as (
Select cyclone_asset as fault_type, 
  sum(case when call_status = 'C' then 1 else 0 end) as complete,
  sum(case when call_status = 'D' then 1 else 0 end) as dispatched,
  sum(case when call_status = 'E' then 1 else 0 end) as entered,
  sum(case when call_status = 'H' then 1 else 0 end) as on_hold,
  sum(case when call_status = 'I' then 1 else 0 end) as started_work
  from disp_status 
  group by cyclone_asset)
select * from status_final order by (complete + dispatched + entered + on_hold + started_work) desc;
  `,
  },
  ProgressClaims: {
    results: [],
    sql: `
          WITH 
          base_dispatch AS
            ( SELECT contract_id,
                    dispatch_id
            FROM mt_dispatch
            WHERE call_status NOT IN ('N')
              AND contract_id IN (2018,
                                  2020,
                                  2021,
                                  2025)
              AND contact_mobile LIKE '2025%'
              AND police_event_id IN ('20230213_08',
                                      '20230110_06',
                                      '20230128_07')
            ),
          claim_lines AS
            (SELECT c.contract_id,
                    c.dispatch_id,
                    c.dispatch_claim_id,
                    c.claim_no,
                    c.account_2,
                    c.claim_status,
                    coalesce(c.claim_amount,0) AS claim_amount
            FROM mt_dispatch_claim c
            INNER JOIN base_dispatch d ON c.contract_id = d.contract_id
            AND c.dispatch_id = d.dispatch_id
            WHERE (c.contract_id = 2025
                    AND c.account_2 = 2
                    AND c.claim_status IN ('A',
                                          'O',
                                          'P'))
              OR (c.contract_id = 2018
                  AND c.account_2 = 32
                  AND c.claim_status IN ('A',
                                          'O',
                                          'P'))
              OR (c.contract_id = 2020
                  AND c.account_2 = 14
                  AND c.claim_status IN ('A',
                                          'O',
                                          'P'))
              OR (c.contract_id = 2021
                  AND c.account_2 = 10
                  AND c.claim_status IN ('A',
                                          'O',
                                          'P'))
            ),
          claim_lines_filt AS
            (SELECT contract_id,
                    claim_no,
                    sum(CASE WHEN c.claim_status = 'A' THEN claim_amount ELSE 0 END) accepted,
                    sum(CASE WHEN c.claim_status = 'A' THEN 0 ELSE claim_amount END) pending
            FROM claim_lines c
            
            GROUP BY contract_id,
                      claim_no
            ),
          claim_numbers AS
            ( SELECT c.contract_id,
                    c.claim_no,
                    cast(year(c.claim_end_date) AS varchar(4)) + '-' + RIGHT('00'+Cast(month(c.claim_end_Date) AS varchar(2)),2) AS claim_date
            FROM mt_claim_header c
            INNER JOIN claim_lines_filt b ON c.contract_id = b.contract_id
            AND c.claim_no = b.claim_no  
            ),
          cost_base AS
            (SELECT a.claim_date,
                    sum(coalesce(accepted,0)) tot_accept,
                    sum(coalesce(pending,0)) tot_pend
            FROM claim_numbers a
            INNER JOIN claim_lines_filt b ON a.contract_id = b.contract_id
            AND a.claim_no = b.claim_no
            GROUP BY a.claim_date)
          select 
              claim_date,
              sum(tot_accept) over (order by claim_date) as cum_accept,
              sum(tot_pend) over (order by claim_date) as cum_pend,
              sum(tot_accept + tot_pend) over (order by claim_date) as cum_total
          from cost_base
            `,
  },
  ProgressClaimsProject: {
    results: [],
    sql: `WITH 
    base_dispatch AS
      ( SELECT contract_id,
              dispatch_id
      FROM mt_dispatch
      WHERE call_status NOT IN ('N')
        AND contract_id IN (2018,
                            2020,
                            2021,
                            2025)
        AND contact_mobile LIKE '2025%'
        AND police_event_id IN ('20230213_08',
                                '20230110_06',
                                '20230128_07')
        AND likely_id in (select cast(system_id as CHAR(3)) from ud_cyclone_projects)
      ),
    claim_lines AS
      (SELECT c.contract_id,
              c.dispatch_id,
              c.dispatch_claim_id,
              c.claim_no,
              c.account_2,
              c.claim_status,
              coalesce(c.claim_amount,0) AS claim_amount
      FROM mt_dispatch_claim c
      INNER JOIN base_dispatch d ON c.contract_id = d.contract_id
      AND c.dispatch_id = d.dispatch_id
      WHERE (c.contract_id = 2025
              AND c.account_2 = 2
              AND c.claim_status IN ('A',
                                    'O',
                                    'P'))
        OR (c.contract_id = 2018
            AND c.account_2 = 32
            AND c.claim_status IN ('A',
                                    'O',
                                    'P'))
        OR (c.contract_id = 2020
            AND c.account_2 = 14
            AND c.claim_status IN ('A',
                                    'O',
                                    'P'))
        OR (c.contract_id = 2021
            AND c.account_2 = 10
            AND c.claim_status IN ('A',
                                    'O',
                                    'P'))
      ),
    claim_lines_filt AS
      (SELECT contract_id,
              claim_no,
              sum(CASE WHEN c.claim_status = 'A' THEN claim_amount ELSE 0 END) accepted,
              sum(CASE WHEN c.claim_status = 'A' THEN 0 ELSE claim_amount END) pending
      FROM claim_lines c
      GROUP BY contract_id,
                claim_no
      ),
    claim_numbers AS
      ( SELECT c.contract_id,
              c.claim_no,
              cast(year(c.claim_end_date) AS varchar(4)) + '-' + RIGHT('00'+Cast(month(c.claim_end_Date) AS varchar(2)),2) AS claim_date
      FROM mt_claim_header c
      INNER JOIN claim_lines_filt b ON c.contract_id = b.contract_id
      AND c.claim_no = b.claim_no  
      ),
    cost_base AS
      (SELECT a.claim_date,
              sum(coalesce(accepted,0)) tot_accept,
              sum(coalesce(pending,0)) tot_pend
      FROM claim_numbers a
      INNER JOIN claim_lines_filt b ON a.contract_id = b.contract_id
      AND a.claim_no = b.claim_no
      GROUP BY a.claim_date)

    select 
        claim_date,
        sum(tot_accept) over (order by claim_date) as cum_accept,
        sum(tot_pend) over (order by claim_date) as cum_pend,
        sum(tot_accept + tot_pend) over (order by claim_date) as cum_total
    from cost_base

        `,
  },
  ProjectClaims: {
    results: [],
    sql: `
    With base_dispatch as (
      SELECT
          contract_id, dispatch_id, likely_id 
              from mt_dispatch
                  Where call_status not in ('N')
                          and
                      contract_id in (2018, 2020, 2021, 2025)
                          and
                      contact_mobile Like '2025%'
                          and
                      police_event_id in ('20230213_08', '20230110_06','20230128_07')   
),--Claim amount
claim_lines as (
select c.contract_id, c.dispatch_id, c.dispatch_claim_id, c.claim_no, c.account_2, c.claim_status, d.likely_id,
coalesce(c.est_amount, 0) as estimate_amount, coalesce(c.claim_amount,0) as claim_amount
  from mt_dispatch_claim c inner join base_dispatch d on c.contract_id = d.contract_id and c.dispatch_id = d.dispatch_id
),
claim_lines_accept as (
select contract_id, dispatch_id, likely_id, sum(claim_amount) as sum_claim_amount
  from claim_lines c
      where 
           (c.contract_id = 2025 and c.account_2 = 2 and c.claim_status in ('A') ) 
          Or (c.contract_id = 2018 and c.account_2 = 32 and c.claim_status in ('A') ) 
          or (c.contract_id = 2020 and c.account_2 = 14 and c.claim_status in ('A') )
          or (c.contract_id = 2021 and c.account_2 = 10 and c.claim_status in ('A') )
      group by contract_id, dispatch_id, likely_id
),--Pending amounts
claim_lines_pending as (
Select contract_id, dispatch_id, likely_id, sum(claim_amount) as sum_pending_amount
  from claim_lines c
      where 
           (c.contract_id = 2025 and c.account_2 = 2 and c.claim_status in ('O','P') ) 
          Or (c.contract_id = 2018 and c.account_2 = 32 and c.claim_status in ('O','P') ) 
          or (c.contract_id = 2020 and c.account_2 = 14 and c.claim_status in ('O','P') )
          or (c.contract_id = 2021 and c.account_2 = 10 and c.claim_status in ('O','P') )
      group by contract_id, dispatch_id, likely_id
),
claim_lines_estimate as (
select contract_id, dispatch_id, likely_id,  sum(estimate_amount) as sum_estimate_amount
  from claim_lines c
      where 
           c.claim_status = 'O'
      group by contract_id, dispatch_id, likely_id
),
projects as (select d.contract_id, d.dispatch_id, p.project_name, 
                  a.sum_claim_amount, b.sum_pending_amount, sum_estimate_amount
  from ud_cyclone_projects p inner join mt_dispatch d on cast(p.system_id as CHAR(3)) = d.likely_id
      left join claim_lines_accept a on a.contract_id = d.contract_id and a.dispatch_id = d.dispatch_id
      left join claim_lines_pending b on b.contract_id = d.contract_id and b.dispatch_id = d.dispatch_id
      left join claim_lines_estimate c on c.contract_id = d.contract_id and c.dispatch_id = d.dispatch_id
)

Select project_name, sum(coalesce(sum_claim_amount,0)) as claim_tot, 
sum(coalesce(sum_pending_amount,0)) as pend_tot, 
sum(coalesce(sum_estimate_amount,0)) as est_tot
from projects
group by project_name
            `,
  },
  MapData: {
    results: [],
    sql: `
    With base_dispatch as (
      SELECT
          contract_id, dispatch_id 
              from mt_dispatch
                  Where call_status not in ('N')
                          and
                      contract_id in (2018, 2020, 2021, 2025)
                          and
                      contact_mobile Like '2025%'
                          and
                      police_event_id in ('20230213_08', '20230110_06','20230128_07')   
),

disp_status as (Select c.fault, c.call_status, a.cyclone_asset, c.northing, c.easting,
      case
          when disp_group_5 = 1 then 'Simple'
          when disp_group_5 = 2 then 'Minor'
          when disp_group_5 = 3 then 'Complex'
          else 'unknown'
      end as severity,
      case 
          when call_status = 'C' then 'Complete'
          when call_status = 'D'  then 'Dispatched'
          when call_status = 'I' then 'Started Work'
          when call_status = 'E' then 'Entered'
          when call_status = 'H' then 'On Hold'
          else 'Other'
      end as stat
  from mt_dispatch c inner join base_dispatch d on c.contract_id = d.contract_id and c.dispatch_id = d.dispatch_id
                     inner join ud_assets_n_faults a on c.asset_type = a.asset_type and c.fault = a.fault_type 
)
Select cyclone_asset as fault_type, stat, northing, easting, severity
  from disp_status
    `,
  },
  SummaryClosures: {
    results: [],
    sql: `
      with counts as (
        select case when caution = 'True' then 'caution' else 'none' end caution_status,
               case when end_date is null then 'closed' else 'open' end close_status,
               case when restriction = 'True' then 'restricted' else 'none' end as restrict_status,
              'road' as type,
               1 as num
        from ud_onrc_closure
        where start_date >= '2023-02-14'
        and start_date <= '2023-06-01'
        )
        select caution_status, close_status, restrict_status, type, sum(num) total
        from counts
        group by caution_status, close_status, restrict_status, type
      `,
  },
  SummaryClosuresTable: {
    results: [],
    sql: `
      select road_name, 
      start_m,
      end_m,
      closure_desc,
       case when end_date is null then 'closed' else 'open' end close_status, 'Road' as type
        from ud_onrc_closure a
        inner join roadnames r on a.road_id = r.road_id 
        where start_date >= '2023-02-14'
        and start_date <= '2023-06-01'
        order by close_status, road_name, start_m
      `,
  },
  Summary: {
    results: [],
    sql: `
    with base_dispatch as (
      select
          contract_id, dispatch_id 
              from mt_dispatch
                  where call_status not in ('N')
                          and
                      contract_id in (2018, 2020, 2021, 2025)
                          and
                      contact_mobile Like '2025%'
                          and
                      police_event_id in ('20230213_08', '20230110_06','20230128_07')   
),
claim_lines as (
select c.contract_id, c.dispatch_id, c.dispatch_claim_id, c.claim_no, c.account_2, c.claim_status, coalesce(c.claim_amount,0) as claim_amount
  from mt_dispatch_claim c inner join base_dispatch d on c.contract_id = d.contract_id and c.dispatch_id = d.dispatch_id
),
claim_lines_filt as (
select c.contract_id, c.dispatch_id, c.dispatch_claim_id, c.claim_no, c.account_2, c.claim_status, coalesce(c.claim_amount,0) as claim_amount
  from claim_lines c
      Where 
           (c.contract_id = 2025 and c.account_2 = 2 and c.claim_status in ('A', 'O', 'P') ) 
          Or (c.contract_id = 2018 and c.account_2 = 32 and c.claim_status in ('A', 'O', 'P') ) 
          or (c.contract_id = 2020 and c.account_2 = 14 and c.claim_status in ('A', 'O', 'P') )
          or (c.contract_id = 2021 and c.account_2 = 10 and c.claim_status in ('A', 'O', 'P') )
),
dispt_data as (select d.contract_id, d.dispatch_id, 
      case
          when d.disp_group_5 = 1 then 'Simple'
          when d.disp_group_5 = 2 then 'Minor'
          when d.disp_group_5 = 3 then 'Complex'
          else 'unknown'
      end as severity,
      case 
          when d.call_status = 'C' then 'Complete'
          when d.call_status = 'D' then 'Dispatched'
          when d.call_status = 'E' then 'Entered'
          when d.call_status = 'H' then 'On Hold'
          when d.call_status = 'I' then 'Started Work'
          else 'check call status'
      end as stat
  from mt_dispatch d inner join base_dispatch b on d.contract_id = b.contract_id and d.dispatch_id = b.dispatch_id
),
claim_data as (select contract_id, dispatch_id, sum(claim_amount) as sum_claim_amount from claim_lines_filt 
              group by contract_id, dispatch_id
)

Select a.stat, a.severity, sum(coalesce(b.sum_claim_amount,0)) as claim_amount, count(a.dispatch_id) as num
  from dispt_data a 
  left join claim_data b on a.contract_id = b.contract_id and a.dispatch_id = b.dispatch_id 
  group by a.stat, a.severity
      `,
  },
};

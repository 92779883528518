import styled, { keyframes } from "styled-components";

const kf_rotate = keyframes`
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
`;

const kf_prx = keyframes`
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;
const Spinner = styled.span`
  & {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    animation: ${kf_rotate} 1s linear infinite;
  }
  &:before,
  &:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #FFCB05;
    animation: ${kf_prx} 2s linear infinite;
  }
  &:after {
    border-color: #00ABBD;
    animation: ${kf_prx} 2s linear infinite,
    ${kf_rotate} 0.5s linear infinite reverse;
    inset: 6px;
  }
`;

export default Spinner;

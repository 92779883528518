import { Row, Col } from "react-bootstrap";
import Gauge from "../charts/Gauge";
import { format as d3Format, sum as d3Sum } from "d3";
import Spinner from "../components/Spinner";

const SummaryItems = (props) => {

  const qry = props.qry;

  if (qry.isLoading) {
    return (
      <div
        style={{
          height: "50px",
          width: "50px",
          display: "flex",
          justifyContext: "center",
        }}
      >
        <Spinner></Spinner>
      </div>
    );
  }

  if (qry.isError) {
    return <div>{qry.error.message}</div>;
  }

  const dollarFormat = d3Format("$.4s");
  const percFormat = d3Format(".3s");

  const data = qry.data;

  const minor_count = d3Sum(
    data.filter((s) => s.severity === "Minor"),
    (d) => d.num
  );
  const simple_count = d3Sum(
    data.filter((s) => s.severity === "Simple"),
    (d) => d.num
  );
  const complex_count = d3Sum(
    data.filter((s) => s.severity === "Complex"),
    (d) => d.num
  );
  const complete_count = d3Sum(
    data.filter((s) => s.stat === "Complete"),
    (d) => d.num
  );
  const complete_perc =
    (complete_count * 100) / (simple_count + minor_count + complex_count + 1);

  const total_exp = d3Sum(data, (d) => d.claim_amount);

  return (
    <>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "#72727D",
              color: "white",
              textAlign: "center",
              fontWeight: "bold",
              borderRadius: "10px",
            }}
          >
            <span style={{ fontSize: "2rem" }}>Total Repairs to Complete</span>
            <br />
            <span style={{ fontSize: "3.2rem" }}>
              {simple_count + minor_count + complex_count}
            </span>
          </div>
        </Col>
        <Col>
          <div
            style={{
              backgroundColor: "#ED1C24",
              color: "white",
              textAlign: "center",
              fontWeight: "bold",
              borderRadius: "10px",
            }}
          >
            <span style={{ fontSize: "2rem" }}>Expenditure to Date</span>
            <br />
            <span style={{ fontSize: "3.2rem" }}>
              {dollarFormat(total_exp)}
            </span>
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: "0.3rem" }}>
        <Col>
          <Row>
            <Col style={{}}>
              <div
                style={{
                  backgroundColor: "#F37021",
                  color: "white",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  height: "100px",
                }}
              >
                <div style={{ fontSize: "1rem" }}>Complex Repairs</div>
                <div style={{ fontSize: "2rem" }}>{complex_count}</div>
              </div>
            </Col>
            <Col>
              <div
                style={{
                  backgroundColor: "#FFCB05",
                  color: "white",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  height: "100px",
                  justifyContent: "middle",
                }}
              >
                <div style={{ fontSize: "1rem" }}>Simple Repairs</div>
                <div style={{ fontSize: "2rem" }}>{simple_count}</div>
              </div>
            </Col>
            <Col>
              <div
                style={{
                  backgroundColor: "#00ABBD",
                  color: "white",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  height: "100px",
                }}
              >
                <div style={{ fontSize: "1rem" }}>Minor Repairs</div>
                <div style={{ fontSize: "2rem" }}>{minor_count}</div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <div
                style={{
                  backgroundColor: "#0DB14B",
                  color: "white",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  height: "100px",
                }}
              >
                <div style={{ fontSize: "1rem" }}>Repairs Completed</div>
                <div style={{ fontSize: "2rem" }}>
                  {percFormat(complete_perc)}%
                </div>
              </div>
            </Col>
            <Col>
              <div
                style={{
                  backgroundColor: "white",
                  color: "#0DB14B",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  height: "100px",
                }}
              >
                <div style={{ fontSize: "1rem" }}>Repairs Completed</div>
                <div style={{ display: "inline-block", alignItems: "center" }}>
                  <Gauge
                    height={100}
                    width={100}
                    totalNumber={simple_count + minor_count + complex_count}
                    label={complete_count}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SummaryItems;

import { useAppContext } from "../context/AppContext";
import { filterAll, renderAll } from "dc";
import { Badge, Button } from 'react-bootstrap'
const handleClick = () => {
    filterAll();
    renderAll();
}

const FilterCounter = () => {
    const { filterCount } = useAppContext();
    return (
        <span style={{ color: "white" }}>
            { filterCount > 0 ? (<>Active Filters: <Badge bg="primary">{ filterCount }</Badge><Badge bg="warning" style={{ marginLeft: "1rem", color: "black" }} onClick={handleClick}>Reset All Filters</Badge></>) : (<></>) }
            
        </span>
    )
}

export default FilterCounter;
import { format as d3Format } from "d3";
import Spinner from "../components/Spinner";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { useAppContext } from "../context/AppContext";

const dollarFormat = d3Format("$.3s");
const ProjectClaims = () => {

  const state = useAppContext();
  const data = state.queries["ProjectClaims"].results;

  if (data.length > 0) {
    return (
      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          layout="vertical"
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barCategoryGap="0%"
        >
          <CartesianGrid strokeDasharray="3 3" />
          
          <XAxis tickFormatter={(value) => dollarFormat(value)} type="number" />
          <Tooltip formatter={(value) => dollarFormat(value)} />
          <Legend />
          <Bar
            name="Claims Loaded"
            stackId="a"
            dataKey="claims"
            fill="#0066b3"
          />          
          <YAxis
            dataKey="project_name"
            allowDataOverflow={true}
            type="category"
            style={{
              fontSize: "0.7rem",
              fill: "black",
              fontWeight: "600",
              padding: "0"
            }}
            mirror={true}
            width={400}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  } else {
    return(<div style={{ height: "50px", width: "50px", display: "flex", justifyContext: "center"}} ><Spinner></Spinner></div>)
  }
};

export default ProjectClaims;

import { useAppContext } from "../context/AppContext";
import { useQuery } from "@tanstack/react-query";
import { queries } from "../fetch/rammQueries";
import { fetchQuery } from "../fetch/fetchData";
import { Container, Row, Col } from "react-bootstrap";
import SummaryItems from "./SummaryItems";
import ClosuresTable from "./ClosuresTable";
import Closures from "./Closures";

const SummaryMain = () => {
  const state = useAppContext();

  const sqlSummary = queries["Summary"].sql;
  const sqlClosures = state.queries["SummaryClosures"].sql;
  const sqlClosuresTable = state.queries["SummaryClosuresTable"].sql;

  const token = state.token;

  const qrySummary = useQuery({
    queryKey: ["Summary", token],
    queryFn: async () => {
      const data = await fetchQuery({ token, sql: sqlSummary });
      return data.data;
    },
    enabled: token !== "",
  });

  const qrySummaryClosures = useQuery({
    queryKey: ["SummaryClosures", token],
    queryFn: async () => {
      const data = await fetchQuery({ token, sql: sqlClosures });
      return data.data;
    },
    enabled: token !== "",
  });

  const qrySummaryClosuresTable = useQuery({
    queryKey: ["SummaryClosuresTable", token],
    queryFn: async () => {
      const data = await fetchQuery({ token, sql: sqlClosuresTable });
      return data.data;
    },
    enabled: token !== "",
  });

  return (
    <Container>
     <SummaryItems qry={qrySummary} />
      <hr/>
      <Row style={{ marginTop: "0.3rem" }}>
        <Col>
         <ClosuresTable qry={qrySummaryClosuresTable} />
        </Col>
        <Col>
        <Closures qry={qrySummaryClosures} />
        </Col>
      </Row>
    </Container>
  );
};

export default SummaryMain;

import { Card, Col } from "react-bootstrap";
function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}
function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const SeverityBox = (props) => {
  const col = rgbToHex(props.colour[0], props.colour[1], props.colour[2]);
  const settings = { backgroundColor: col, borderWidth: "2px", borderColor: props.selected ? "chartreuse" : "red"} 
  return (
    <Col xs={4}>
      <Card style={settings} onClick={()=>{props.onPress(props.severity)}}>
        <Card.Header style={{ textAlign: "center", fontSize: "1rem", padding: "0.1rem" }}>
          {props.severity}
        </Card.Header>
        <Card.Body
          style={{
            padding: "0.2rem",
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          {props.count}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default SeverityBox;

import {
  useContext,
  createContext,
  useReducer,
  useMemo,
  useState,
  useEffect,
} from "react";
import reducer from "./reducer";
import { fetchQueryMultiple } from "../fetch/fetchData";
import fetchToken from "../fetch/fetchToken";
import { queries } from "../fetch/rammQueries";
export const AppContext = createContext();

const initialState = {
  data: [],
  loggedIn: false,
  loggingIn: false,
  loginMessage: "",
  querying: false,
  queryMessage: "",
  token: "",
  queries,
};

export function AppContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [token, setToken] = useState("");

  const login = async () => {
    const res = await fetchToken({
      username: "dhpj",
      password: "Ultimate#101",
      database: "Central Hawkes Bay District Council",
    });

    if (res?.status === 200) {
      setToken(res.data);
      dispatch({
        type: "login_success",
        payload: { token: res.data },
      });
    } else if (res?.status === 401) {
      dispatch({
        type: "login_failure",
        payload: { message: "Incorrect username or password" },
      });
    } else {
      dispatch({
        type: "login_failure",
        payload: { message: res.message },
      });
    }
  };

  useMemo(() => {
    
    dispatch({
      type: "login_start",
      payload: {},
    });
    login();
  }, []);
  /*
  useEffect(() => {
    const fetchAllData = async () => {
      const queryKeys = Object.keys(queries)
      const sql = queryKeys
        .map((q) => queries[q].sql)
        .join(";");
  
      const res = await fetchQueryMultiple({
        token,
        sql
      });

      if (res?.status === "OK") {
        let i = 0;
        res.data.forEach((d) => {
          if(d.type === 'RESULT-SET') {
            dispatch({
              type: "load_bulk_query_result",
              payload: { query: queryKeys[i], data: d.value}
            })
            i++;
          }
        })
  
      } else {
      }
    };
    if (token !== "") fetchAllData();
  }, [token]);
  */
  const value = {
    ...state,
  };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

export function useAppContext() {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("incorrect context");
  }

  return context;
}

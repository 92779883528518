import { Container, Row, Col } from "react-bootstrap";
import CostProgress from "../charts/CostProgress";
import ProgressClaims from "../charts/ProgressClaims";
import FaultSeverity from "../charts/FaultSeverity";
import FaultStatus from "../charts/FaultStatus";

const Charts = () => {
  return (
    <>
      <h2>Programme Progress</h2>
      <Container fluid>
        <Row>
          <Col md={4} sm={12}>
          <h5>Costs Loaded to Date</h5>
            <CostProgress />
          </Col>
          <Col md={4} sm={12}>
          <h5>Count of Faults by Severity</h5>
            <FaultSeverity />
          </Col>
          <Col md={4} sm={12}>
          <h5>Count of Faults by Status</h5>
            <FaultStatus />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
          <h5>Progressive Claims</h5>
          <ProgressClaims />
          </Col>
        </Row>      
      </Container>
    </>
  );
};

export default Charts;

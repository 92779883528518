import { Row, Col } from "react-bootstrap";
import Spinner from "../components/Spinner";
import { format as d3Format, sum as d3Sum } from "d3";

const Closures = (props) => {
  const qry = props.qry;

  if (qry.isLoading) {
    return (
      <div
        style={{
          height: "50px",
          width: "50px",
          display: "flex",
          justifyContext: "center",
        }}
      >
        <Spinner></Spinner>
      </div>
    );
  }

  if (qry.isError) {
    return <div>{qry.error.message}</div>;
  }
  
  const closures = qry.data;

  const all_closures = d3Sum(closures, (d) => d.total);
  const all_cautions = d3Sum(
    closures.filter((d) => d.caution_status === "caution"),
    (d) => d.total
  );
  const all_closed = d3Sum(
    closures.filter((d) => d.close_status === "closed" && d.type === "road"),
    (d) => d.total
  );
  const all_restrict = d3Sum(
    closures.filter(
      (d) => d.restrict_status === "restricted" && d.type === "road"
    ),
    (d) => d.total
  );
  const all_open = d3Sum(
    closures.filter((d) => d.close_status === "open" && d.type === "road"),
    (d) => d.total
  );

  return (
    <>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "#C81A78",
              color: "white",
              textAlign: "center",
              fontWeight: "bold",
              borderRadius: "5px",
              height: "100px",
            }}
          >
            <div style={{ fontSize: "1rem" }}>All Closures from CG</div>
            <div style={{ fontSize: "2rem" }}>{all_closures}</div>
          </div>
        </Col>
        <Col>
          <div
            style={{
              backgroundColor: "#72727D",
              color: "white",
              textAlign: "center",
              fontWeight: "bold",
              borderRadius: "5px",
              height: "100px",
            }}
          >
            <div style={{ fontSize: "1rem" }}>Number of Cautions</div>
            <div style={{ fontSize: "2rem" }}>{all_cautions}</div>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "0.3rem" }}>
        <Col>
          <div
            style={{
              backgroundColor: "#ED1C24",
              color: "white",
              textAlign: "center",
              fontWeight: "bold",
              borderRadius: "5px",
              height: "60px",
            }}
          >
            <div style={{ fontSize: "0.6rem" }}>Sites Still Closed</div>
            <div style={{ fontSize: "1.5rem" }}>{all_closed}</div>
          </div>
        </Col>
        <Col>
          <div
            style={{
              backgroundColor: "#F37021",
              color: "white",
              textAlign: "center",
              fontWeight: "bold",
              borderRadius: "5px",
              height: "60px",
            }}
          >
            <div style={{ fontSize: "0.6rem" }}>Restricted</div>
            <div style={{ fontSize: "1.5rem" }}>{all_restrict}</div>
          </div>
        </Col>
        <Col>
          <div
            style={{
              backgroundColor: "#0DB14B",
              color: "white",
              textAlign: "center",
              fontWeight: "bold",
              borderRadius: "5px",
              height: "60px",
            }}
          >
            <div style={{ fontSize: "0.6rem" }}>Reopened</div>
            <div style={{ fontSize: "1.5rem" }}>{all_open}</div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Closures;

//import backgroundStyle from "./backgroundStyle";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import HeaderBar from "./components/HeaderBar";
import Projects from "./components/Projects";
import Charts from "./components/Charts";
import MapMain from "./components/MapMain";
import SummaryMain from "./components/SummaryMain"
function App() {
  return (
    <>
      <HeaderBar />
      <Container fluid>
        <Tabs
          defaultActiveKey="programme"
          id="uncontrolled-tab-example"
          className="mb-1"
        >
          <Tab eventKey="programme" title="Programme">
            <Charts />
          </Tab>
          <Tab eventKey="projects" title="Projects">
            <Projects />
          </Tab>
          <Tab eventKey="map" title="Maps">
            <MapMain />
          </Tab>
          <Tab eventKey="summary" title="Summary">
            <SummaryMain />
          </Tab>
        </Tabs>
      </Container>
      </>
  );
}

export default App;

import { format as d3Format } from "d3";
import Spinner from "../components/Spinner";
import { useQuery } from "@tanstack/react-query";
import { queries } from "../fetch/rammQueries";
import { fetchQuery } from "../fetch/fetchData";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { useAppContext } from "../context/AppContext";

const CostProgress = () => {

  const state = useAppContext();
  const sql = queries['CostProgress'].sql;
  const token = state.token;

  const qry = useQuery({
    queryKey: ['CostProgress', token],
    queryFn: async() => {
      const data = await fetchQuery({token, sql});
      return data.data
    },
    enabled: token!==""
  })
  
  const dollarFormat = d3Format("$.3s");

  if(qry.isLoading) {
    return(<div style={{ height: "50px", width: "50px", display: "flex", justifyContext: "center"}} ><Spinner></Spinner></div>)
  }

  if(qry.isError) {
    return(<div>{qry.error.message}</div>)
  }

  return (
    <ResponsiveContainer height={400}>
      <BarChart
        data={qry.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis />
        <YAxis tickFormatter={(value) => dollarFormat(value)} />
        <Tooltip formatter={(value) => dollarFormat(value)} />
        <Legend wrapperStyle={{fontSize: "0.7rem"}} />
        <Bar name="Programme Estimate" dataKey="est_tot" fill="#0db14b" stackId="a"/>
        <Bar name="Claims Accepted" dataKey="claim_tot" fill="#0066b3" stackId="b" />
        <Bar name="Claims Pending" dataKey="pend_tot" fill="#8884d8" stackId="b" />
      </BarChart>
    </ResponsiveContainer>
  )
};

export default CostProgress;

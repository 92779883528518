import axios from "axios";
import { parseRammQueryResults } from "../utils/utils";

const fetchQuery = async (props) => {
  
  const { token, sql } = props;
  const auth = `Bearer ${token}`;

  const req = await axios({
    method: "post",
    url: "https://sqlmanager-auea.ramm.com/queryeditor/runquery",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth,
    },
    transformResponse: function (data) {
      let resp = { };
      let status = "OK";

      try {
        const jsonString = data
          .substring(data.indexOf("["), data.lastIndexOf("]") + 1)
          .replace(/[\n\r]/g, "");

        if (jsonString === "[]") {
          throw "No records";
        } else {
          resp = JSON.parse(
            jsonString.substring(0, jsonString.length - 2) + "]"
          );
        }
        resp = JSON.parse(data.split(",\n\n]")[0].slice(33) + "]");
      } catch (error) {
        console.log(error);
        status = "Error";
        resp = [{ status: "Error" }];
      }

      return { status, data: resp };
    },
    data: {
      query: `${sql}`,
      securityZoneId: 1,
      autoLookupExpanded: false,
      wrapInTransaction: false,
      addTransactionRollback: false,
      signalRGridData: false,
      recordQueryCost: false,
    },
  }).catch((error) => {
    console.log("Fetch Error: ", error);
  });

  return req.data;
};

const fetchMedia = async (props) => {
  
  const { token, table, assetIds } = props;
  const url = `https://api-auea.ramm.com:443/v1//multimedia/${table}?assetIds=${assetIds.join(",")}&token=${token}`;

  const req = await axios({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json"
    }
  }).catch((error) => {
    console.log("Fetch Error: ", error);
  });

  return req
};

const fetchQueryMultiple = async (props) => {
  
  const { token, sql } = props;
  const auth = `Bearer ${token}`;

  const req = await axios({
    method: "post",
    url: "https://sqlmanager-auea.ramm.com/queryeditor/runquery",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth,
    },
    transformResponse: function (data) {
      let resp = parseRammQueryResults(data);
      let status = "OK";
      
      return { status, data: resp };
    },
    data: {
      query: `${sql}`,
      securityZoneId: 1,
      autoLookupExpanded: false,
      wrapInTransaction: false,
      addTransactionRollback: false,
      signalRGridData: false,
      recordQueryCost: false,
    },
  }).catch((error) => {
    console.log("Fetch Error: ", error);
  });

  return req.data;
};

export { fetchQuery, fetchQueryMultiple, fetchMedia };

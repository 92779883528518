import axios from 'axios';

const fetchToken = async (props) => {
  const { username, password, database } = props;

  return await axios.post(`https://api-auea.ramm.com/v1/authenticate/login`, {
    database: database,
    userName: username,
    password: password,
  }).catch((error) =>{
    return { status: error.response.status, message: error.response.statusText }
  })

};

export default fetchToken;

import { Card, Col } from "react-bootstrap";


const FaultBox = (props) => {
    const settings = { marginTop: "0.3rem", backgroundColor: '#8dc63f', borderWidth: "2px", borderColor: props.selected ? "chartreuse" : "red"} 
  return (
    <Col xs={4}>
      <Card style={settings} onClick={()=>{props.onPress(props.fault)}}>
        <Card.Header style={{ textAlign: "center", fontSize: "0.8rem", padding: "0.05rem" }}>
          {props.fault.length > 25 ? props.fault.substring(0,25) + "..." : props.fault}
        </Card.Header>
        <Card.Body
          style={{
            padding: "0.2rem",
            textAlign: "center",
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          {props.count}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default FaultBox;

import {
    ResponsiveContainer,
    Sector,
    Cell,
    PieChart,
    Pie,
    Tooltip,
    Label
  } from "recharts";

  const Gauge = (props) => {
    const RADIAN = Math.PI / 180;
  
    const width = props.width;
    const height = props.height;
    const chartValue = props.label;
    const slices = [
      {
        value: Number(chartValue),
        color: "#0DB14B"
      },
      {
        value: Number(props.totalNumber) - Number(chartValue),
        color: "lightgrey"
      }

    ];
  
    const sumValues = slices.map((cur) => cur.value).reduce((a, b) => a + b);
  
    const arrowData = [
      { value: chartValue },
      { value: 0 },
      { value: sumValues - chartValue }
    ];
  
    const pieProps = {
      startAngle: 180,
      endAngle: 0,
      cx: width / 2,
      cy: width / 2,
      isAnimationActive: false
    };
  
    return (
      <div style={{ width, height }}>
        <ResponsiveContainer>
          <PieChart width={width} height={height}>
            <Pie
              stroke="none"
              data={slices}
              innerRadius={(width / 2) * 0.5}
              outerRadius={(width / 2) * 0.8}
              {...pieProps}
              // label
              //label={chartValue}
            >
              {slices.map((each, i) => (
                <Cell key={`cell-${i}`} fill={slices[i].color} stroke="none" />
              ))}
            </Pie>
            <Pie
              stroke="none"
              fill="none"
              activeIndex={1}
              data={arrowData}
              innerRadius={(width / 2) * 0.5}
              outerRadius={(width / 2) * 0.6}
              {...pieProps}
            >
              <Label
                value={chartValue}
                position="centerBottom" //
                offset={-20}
                className="gauge-label"
                fontSize="1rem"
                fontWeight="bold"
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };
  
  export default Gauge;
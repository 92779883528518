import { fetchMedia, fetchQuery } from "../fetch/fetchData";
import { useEffect, useState } from "react";
import { useAppContext } from "../context/AppContext";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Carousel } from "react-responsive-carousel";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "./Spinner";
import MiniMap from "./MiniMap";
import { convertToLatLong } from "../utils/utils";
import DeckGL from "@deck.gl/react";
import { Map } from "react-map-gl";
import { ScatterplotLayer } from "deck.gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { mean as d3Mean} from "d3";
import Gauge from "../charts/Gauge";
const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoicGV0ZWhpbGxqbnIiLCJhIjoiY2swcjZtd3IxMDJjOTNjb3c1Z25wczQ3NyJ9.2Ut4kmGeVuvc7UUb-qezNw";

const MAP_STYLE = "mapbox://styles/mapbox/dark-v9";

function ProjectSummary(props) {
  const { token } = useAppContext();
  //const [data, setData] = useState([]);
  const [media, setMedia] = useState([]);
  const [assetIds, setAssetIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [coords, setCoords] = useState([]);
  const [view, setView] = useState({
    longitude: 176.6,
    latitude: -40.1,
    zoom: 12,
    pitch: 0,
    bearing: 0 });

  const layer = new ScatterplotLayer({
    data: coords,
    pickable: false,
    opacity: 1,
    stroked: true,
    filled: true,
    radius: 1000,
    radiusScale: 6,
    radiusMinPixels: 4,
    radiusMaxPixels: 100,
    lineWidthMinPixels: 1,
    getPosition: (d) => [d[1], d[0]],
    getRadius: 10,
    getFillColor: (d) => [255,0,0],
    getLineColor: (d) => [255, 0, 0]

  });

  const { isOpen, toggle, project } = props;
  const table = "ud_cyclone_faults";

  useEffect(() => {    
    setLoading(true)
    setAssetIds([]);
    setCoords([]);
    setMedia([]);
    const getAssetIds = async () => {
      const sql = `
        with projects as (
          select system_id as project_id
          from ud_cyclone_projects
          where project_name = '${project}'
      )
      select system_id, northing, easting
      from ud_cyclone_faults f
      inner join projects p on f.project_id = p.project_id
      `;
      const res = await fetchQuery({ token, sql });
      if (res?.status === "OK") {
        const newCoords = res.data.map(d => convertToLatLong(+d.easting, +d.northing))
        setAssetIds(res.data.map(d => +d.system_id));
        setCoords(newCoords);
        const meanLat = d3Mean(newCoords, d => d[0]);
        const meanLng = d3Mean(newCoords, d => d[1]);
        setView({...view, latitude: meanLat, longitude: meanLng})
      } else {
        console.log(res) // error has occurred
      }
    };

    if (token !== "") getAssetIds();
  }, [project]);

  useEffect(() => {
    const getMedia = async (id) => {
      const res = await fetchMedia({ token, table, assetIds: [id] });
      if (res?.statusText === "OK") {
        setMedia(m=> [...m, ...res.data.filter(d => d.dataUrl !== null).filter(d => d.metaInformation.fileSize >= 10)]);
      } else {
      }
    };
    if (token !== "") {      
      assetIds.forEach(d => getMedia(d))
      setLoading(false);    
    }
  }, [assetIds, token]);

  return (
    <Modal show={isOpen} size="xl">
      <Modal.Header>        
        <Modal.Title>{project} { loading ? <div style={{ width: "16px", height: "16px"}}><Spinner /> </div>: <></>}</Modal.Title>        
      </Modal.Header>
      <Modal.Body style={{ height: "300px" }}>
        <Container>
          <Row>
            <Col lg={{ span: 3 }} style={{}}>
              <h4>Photos</h4>
              <Carousel
                showThumbs={false}
                height={150}
                centerMode={true}
                centerSlidePercentage={100}
                infiniteLoop={true}
                renderIndicator={false}
              >
                {media.map((d) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <a
                        href={`https://coreservices-auea.ramm.com//v2/multimedia/detail/media/${d.id}/?token=${token}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={`data:image/png;base64,${d.dataUrl}`}
                          alt=""
                          height={200}
                        />
                      </a>
                    </div>
                  );
                })}
              </Carousel>
            </Col>
            <Col lg={{ span: 4 }} style={{height: "200px"}}>
              <h4>Location</h4>
              <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <DeckGL
              layers={layer}
              initialViewState={view}
              controller={true}
            >
              <Map
                mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                mapStyle={MAP_STYLE}
                reuseMaps
                preventStyleDiffing={true}
              />
            </DeckGL>
          </div>
            </Col>
            <Col style={{height: "200px"}}>
            <h4>Status</h4>
            <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <Gauge
                    height={300}
                    width={400}
                    totalNumber={100}
                    label={25}
                  />
            </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggle}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProjectSummary;

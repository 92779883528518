import { useState } from 'react';
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import CostProgressProjects from "../charts/CostProgressProjects";
import ProgressClaimsProject from "../charts/ProgressClaimsProject";
import ProjectEstimates from "../charts/ProjectEstimates";
import ProjectClaims from "../charts/ProjectClaims";

const Projects = () => {


  return (
    <>
      <h2>Project Progress</h2>
      <Container fluid>
        <Row>
          <Col md={4} sm={12}>
          <h5>Costs Loaded to Date</h5>
            <CostProgressProjects />
          </Col>
          <Col md={4} sm={12}>
          <h5>Project Estimates vs Claims</h5>
          <ProjectEstimates />
          </Col>
          <Col md={4} sm={12}>
          <h5>Project Status</h5>
          <ProjectClaims />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
          <h5>Progressive Claims</h5>
          <ProgressClaimsProject />
          </Col>
        </Row>
      
      </Container>
    </>
  );
};

export default Projects;

import Spinner from "../components/Spinner";

const ClosuresTable = (props) => {
  const qry = props.qry;

  if (qry.isLoading) {
    return (
      <div
        style={{
          height: "50px",
          width: "50px",
          display: "flex",
          justifyContext: "center",
        }}
      >
        <Spinner></Spinner>
      </div>
    );
  }

  if (qry.isError) {
    return <div>{qry.error.message}</div>;
  }

  const ctable = qry.data;

  return (
    <div
      style={{
        backgroundColor: "#72727D",
        color: "white",
        textAlign: "center",
        fontWeight: "bold",
        borderRadius: "5px",
      }}
    >
      <span>Road Closures</span>
      <div
        style={{
          height: "400px",
          width: "100%",
          padding: "5px",
          overflowY: "scroll",
        }}
      >
        <table style={{ fontSize: "0.6rem" }}>
          <tbody>
            {ctable.map((d,i) => {
              return (
                <tr style={{ borderBottom: "1px solid #72727D" }} key={i}>
                  <td>
                    <strong>{d.road_name}</strong>
                    {` - ${d.start_m}m to ${d.end_m}`}
                  </td>
                  <td>{d.closure_desc}</td>
                  <td>{d.close_status === "closed" ? "Closed" : "Reopened"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClosuresTable;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./App.css";
import { AppContextProvider } from "./context/AppContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 60 * 5, cacheTime: 1000 * 60 * 10 } },
})

root.render(
  <React.StrictMode>
    
    <AppContextProvider>
    <QueryClientProvider client={queryClient}>
      <App />
      </QueryClientProvider>
    </AppContextProvider>
    
  </React.StrictMode>
);
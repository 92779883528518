import Spinner from "../components/Spinner";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { useAppContext } from "../context/AppContext";
import { useQuery } from "@tanstack/react-query";
import { queries } from "../fetch/rammQueries";
import { fetchQuery } from "../fetch/fetchData";

const FaultStatus = () => {

  const state = useAppContext();
  const sql = queries['FaultStatus'].sql;
  const token = state.token;

  const qry = useQuery({
    queryKey: ['FaultStatus', token],
    queryFn: async() => {
      const data = await fetchQuery({token, sql});
      return data.data
    },
    enabled: token!==""
  })


  if(qry.isLoading) {
    return(<div style={{ height: "50px", width: "50px", display: "flex", justifyContext: "center"}} ><Spinner></Spinner></div>)
  }

  if(qry.isError) {
    return(<div>{JSON.stringify(qry.error.message)}</div>)
  }

    return (
      <ResponsiveContainer height={400}>
        <BarChart
          layout="vertical"
          data={qry.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          
          <XAxis type="number" />
          <Tooltip />
          <Legend wrapperStyle={{fontSize: "0.7rem"}}/>
          <Bar
            name="Entered"
            stackId="a"
            dataKey="entered"
            fill="#f99d1c"
          />
          <Bar
            name="Dispatched"
            stackId="a"
            dataKey="dispatched"
            fill="#ed1c24"
          />
          <Bar
            name="On Hold"
            stackId="a"
            dataKey="on_hold"
            fill="#00abbd"
          />
          <Bar
            name="Started Work"
            stackId="a"
            dataKey="started_work"
            fill="#006180"
          />
          <Bar
            name="Complete"
            stackId="a"
            dataKey="complete"
            fill="#8dc63f"
          />
          <YAxis
            dataKey="fault_type"
            name = "Fault Type"
            type="category"
            interval={0}
            style={{
              fontSize: "0.8rem",
              fill: "black",
              fontWeight: "600"
            }}
            mirror={true}
            width={400}
          />
        </BarChart>
      </ResponsiveContainer>
    )
};

export default FaultStatus;

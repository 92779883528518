
import { useState, useEffect } from "react";
import DeckGL from "@deck.gl/react";
import { Map } from "react-map-gl";
import { ScatterplotLayer } from "deck.gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Container, Row, Col } from "react-bootstrap";
import { convertToLatLong } from "../utils/utils";
import SeverityBox from "../components/SeverityBox";
import FaultBox from "../components/FaultBox";
import StatusBox from "../components/StatusBox";
import { DataFilterExtension } from "@deck.gl/extensions";
import { useAppContext } from "../context/AppContext";
import { useQuery } from "@tanstack/react-query";
import { queries } from "../fetch/rammQueries";
import { fetchQuery } from "../fetch/fetchData";

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoicGV0ZWhpbGxqbnIiLCJhIjoiY2swcjZtd3IxMDJjOTNjb3c1Z25wczQ3NyJ9.2Ut4kmGeVuvc7UUb-qezNw";

const initialViewState = {
  longitude: 176.6,
  latitude: -40.1,
  zoom: 10,
  pitch: 60,
  bearing: 0,
};

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const MAP_STYLE = "mapbox://styles/mapbox/dark-v9";

const severityColours = {
  Minor: [0, 171, 189],
  Simple: [255, 203, 5],
  Complex: [243, 112, 33],
};

const MapContainer = (props) => {
  const data = props.data
  const [faults, setFaults] = useState(props.fault);
  const [status, setStatus] = useState(props.status);
  const [severity, setSeverity] = useState(props.severity);
  const [filterSev, setFilterSev] = useState(props.severityTypes);
  const [filterFault, setFilterFault] = useState(props.faultTypes);
  const [filterStatus, setFilterStatus] = useState(props.statusTypes);

  const layer = new ScatterplotLayer({
    id: "faults",
    data,
    pickable: true,
    opacity: 0.8,
    stroked: true,
    filled: true,
    radiusScale: 6,
    radiusMinPixels: 4,
    radiusMaxPixels: 100,
    lineWidthMinPixels: 1,
    getPosition: (d) => [d.coords[1], d.coords[0]],
    getRadius: (d) => Math.sqrt(d.exits),
    getFillColor: (d) => d.colour,
    getLineColor: (d) => [0, 0, 0],
    getFilterValue: (d) => [
      Number(filterSev.some((a) => a === d.severity)),
      Number(filterFault.some((a) => a === d.fault_type)),
      Number(filterStatus.some((a) => a === d.stat)),
    ],
    filterRange: [
      [1, 1],
      [1, 1],
      [1, 1],
    ],
    extensions: [new DataFilterExtension({ filterSize: 3 })],
    updateTriggers: {
      getFilterValue: [filterSev, filterFault, filterStatus],
    },
  });

  const handleSeverityClick = (value) => {
    const s = { ...severity };
    s[value].selected = !s[value].selected;

    const selected = Object.keys(s).filter((i) => s[i].selected);
    setFilterSev(selected);
    setSeverity(s);
  };

  const handleFaultClick = (value) => {
    const s = { ...faults };
    s[value].selected = !s[value].selected;

    const selected = Object.keys(s).filter((i) => s[i].selected);
    setFilterFault(selected);
    setFaults(s);
  };

  const handleStatusClick = (value) => {
    console.log(value);
    const s = { ...status };
    s[value].selected = !s[value].selected;

    const selected = Object.keys(s).filter((i) => s[i].selected);
    setFilterStatus(selected);
    setStatus(s);
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={4}>
          <div style={{ height: "85vh" }}>
            <h5>Severity Counts</h5>
            <Row>
              {["Complex", "Simple", "Minor"].map((p) => {
                return (
                  <SeverityBox
                    key={p}
                    severity={p}
                    count={severity[p].count}
                    selected={severity[p].selected}
                    colour={severityColours[p]}
                    onPress={handleSeverityClick}
                  />
                );
              })}
            </Row>
            <hr />
            <h5>Fault Counts</h5>
            <Row>
              {Object.keys(faults).map((p) => {
                return (
                  <FaultBox
                    key={p}
                    fault={p}
                    count={faults[p].count}
                    onPress={handleFaultClick}
                    selected={faults[p].selected}
                  />
                );
              })}
            </Row>
            <hr />
            <h5>Status Counts</h5>
            <Row>
              {Object.keys(status).map((p) => {
                return (
                  <StatusBox
                    key={p}
                    status={p}
                    count={status[p].count}
                    onPress={handleStatusClick}
                    selected={status[p].selected}
                  />
                );
              })}
            </Row>
          </div>
        </Col>
        <Col>
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <DeckGL
              layers={data.length > 0 ? [layer] : []}
              initialViewState={initialViewState}
              controller={true}
            >
              <Map
                mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                mapStyle={MAP_STYLE}
                reuseMaps
                preventStyleDiffing={true}
              />
            </DeckGL>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MapContainer;

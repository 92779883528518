import Spinner from "../components/Spinner";
import { useAppContext } from "../context/AppContext";
import { useQuery } from "@tanstack/react-query";
import { queries } from "../fetch/rammQueries";
import { fetchQuery } from "../fetch/fetchData";
import { convertToLatLong } from "../utils/utils";
import MapContainer from "./MapContainer";

const severityColours = {
    Minor: [0, 171, 189],
    Simple: [255, 203, 5],
    Complex: [243, 112, 33],
  };


const MapMain = () => {
  const state = useAppContext();
  const sql = queries["MapData"].sql;
  const token = state.token;

  const qry = useQuery({
    queryKey: ["MapData", token],
    queryFn: async () => {
      const data = await fetchQuery({ token, sql });
      return data.data;
    },
    enabled: token !== "",
  });

  if (qry.isLoading) {
    return (
      <div
        style={{
          height: "50px",
          width: "50px",
          display: "flex",
          justifyContext: "center",
        }}
      >
        <Spinner></Spinner>
      </div>
    );
  }

  if (qry.isError) {
    return <div>{JSON.stringify(qry.error.message)}</div>;
  }

  const faultTypes = [...new Set(qry.data.map((item) => item.fault_type))];
  const statusTypes = [...new Set(qry.data.map((item) => item.stat))];
  const severityTypes = [...new Set(qry.data.map((item) => item.severity))];

  let data = qry.data;
  let severity = {};
  let fault = {};
  let status = {};

  data.forEach((d) => {
    d.coords = convertToLatLong(+d.easting, +d.northing);
    d.colour = severityColours[d.severity];

    if (severity[d.severity]) {
        severity[d.severity].count = severity[d.severity].count + 1;
    } else {
        severity[d.severity] = { count: 0, selected: true };
    }

    if (fault[d.fault_type]) {
        fault[d.fault_type].count = fault[d.fault_type].count + 1;
    } else {
        fault[d.fault_type] = { count: 0, selected: true };
    }

    if (status[d.stat]) {
        status[d.stat].count = status[d.stat].count + 1;
    } else {
        status[d.stat] = { count: 0, selected: true };
    }
  });

  return(
    <MapContainer data={data} fault={fault} status={status} severity={severity} faultTypes={faultTypes} statusTypes={statusTypes} severityTypes={severityTypes} />
  )

  /*
    setData(data_out);
    setSeverity(s);
    setFaults(f);
    setStatus(st);
    */
};

export default MapMain;

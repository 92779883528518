import Spinner from "../components/Spinner";
import {
  format as d3Format,
  timeParse as d3TimeParse,
  //timeFormat as d3TimeFormat,
  max as d3Max
} from "d3";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { useAppContext } from "../context/AppContext";
import { useQuery } from "@tanstack/react-query";
import { queries } from "../fetch/rammQueries";
import { fetchQuery } from "../fetch/fetchData";

const parseDate = d3TimeParse("%Y-%m");

const dollarFormat = d3Format("$.4s");
const dollarFormatSmall = d3Format("$.3s");
//const formatTime = d3TimeFormat("%Y-%m");

const ProgressClaimsProject = () => {
  const state = useAppContext();
  const sql = queries['ProgressClaimsProject'].sql;
  const token = state.token;

  const qry = useQuery({
    queryKey: ['ProgressClaimsProject', token],
    queryFn: async() => {
      const data = await fetchQuery({token, sql});
      return data.data
    },
    enabled: token!==""
  })

  if(qry.isLoading) {
    return(<div style={{ height: "50px", width: "50px", display: "flex", justifyContext: "center"}} ><Spinner></Spinner></div>)
  }

  if(qry.isError) {
    return(<div>{qry.error.message}</div>)
  }

  const maxValue = Math.round((d3Max(qry.data.map((d) => (+d.cum_total))) * 1.1)/1000000) * 1000000;

    return (
      <ResponsiveContainer height={300}>
        <LineChart
          data={qry.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="claim_date"
            domain={[parseDate("2023-01"), parseDate("2025-12")]}
            //tickFormatter={(t) => formatTime(t)}
          />
          <YAxis
            type="number"
            domain={[0, maxValue]}
            tickFormatter={(value) => dollarFormatSmall(value)}
          />
          <Tooltip
            formatter={(value) => dollarFormat(value)}
            //labelFormatter={(t) => formatTime(t)}
          />
          <Legend  wrapperStyle={{fontSize: "0.7rem"}}/>
          <Line
            name="Claims Accepted"
            dataKey="cum_accept"
            type="monotone"
            stroke="#0066b3"
            activeDot={{ r: 8 }}
          />
          <Line
            name="Claims Total (including Pending)"
            dataKey="cum_total"
            type="monotone"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    )
};

export default ProgressClaimsProject;

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/logo.png';
import FilterCounter from './FilterCounter';

export default function HeaderBar() {

    return (
        <>
          <Navbar variant="dark" style={{ background: "#005964", zIndex: "1000"}}>
            <Container className="d-flex aligns-items-center" fluid>
              <Navbar.Brand>
                <img
                  alt=""
                  src={logo}
                  height="50"
                  className="d-inline-block align-top"
                />{' '}
                <span style={{ fontSize: "2rem",  marginLeft: "1rem", fontFamily: "Roboto Slab"}}>
                Cyclone Gabrielle Recovery - Reporting Tool</span>                
              </Navbar.Brand>
              <span className="pull-right"><FilterCounter /></span>
            </Container>
          </Navbar>
        </>
      );
}
